@import './assets/styles/sanitize.css';
@import './assets/styles/algolia/satellite.css';
@import './assets/styles/algolia/algolia.css';

@font-face {
    font-family: 'MontserratMedium500';
    src: url('./assets/fonts/montserrat-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratSemibold600';
    src: url('./assets/fonts/montserrat-semibold-webfont.ttf')
        format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratBold700';
    src: url('./assets/fonts/montserrat-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratBoldItalics700';
    src: url('./assets/fonts/montserrat-italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CabinRegular400';
    src: url('./assets/fonts/cabin-italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CabinRegular400';
    src: url('./assets/fonts/cabin-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CabinMedium500';
    src: url('./assets/fonts/cabin-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CabinSemibold600';
    src: url('./assets/fonts/cabin-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html,
body {
    margin: 0;
    padding: 0;
    /* reset 1rem to 10px*/
    font-size: 62.5%;
}

* {
    box-sizing: border-box;
    vertical-align: baseline;
    border: 0;
    font-family: 'MontserratMedium500', sans-serif;
    font-size: 1.6rem;
}

*:focus-visible {
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    letter-spacing: 0;
    margin: 0;
    padding: 0;
}

button {
    display: inline-block;
    text-decoration: none;
    place-items: center;
    text-align: center;
    white-space: nowrap;
    padding: 0;
    margin: 0 auto;
    cursor: pointer;
    font-family: 'CabinSemibold600';
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    margin: 0;
}

/* Styled for disabled checkbox and label in add to board modal */
.checkbox-form input[type='checkbox']:disabled + .checkbox-label {
    color: grey;
}

/* Typography */
.body_cabin_sm {
    font-family: 'CabinRegular400';
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    margin: 0;
}
.body_cabin_xs {
    font-family: 'CabinRegular400';
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    margin: 0;
}
.body_montserrat_xs {
    font-family: 'MontserratSemibold600';
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    margin: 0;
}
.body_montserrat {
    font-family: 'MontserratSemibold600';
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    margin: 0;
}

.header_cabin_sm {
    font-family: 'CabinSemibold600';
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    margin: 0;
}
.header_extra_bi {
    font-family: 'MontserratBoldItalics700';
    font-size: 2rem;
    line-height: 3.2rem;
    letter-spacing: 0;
    margin: 0;
}
.header_extra_medium {
    font-family: 'MontserratMedium500';
    font-size: 2rem;
    line-height: 3.2rem;
    letter-spacing: 0;
    margin: 0;
}
.header_large {
    font-family: 'MontserratBold700';
    font-size: 4rem;
    line-height: 4.8rem;
    letter-spacing: 0;
    margin: 0;
}
.header_md {
    font-family: 'MontserratBold700';
    font-size: 3.2rem;
    line-height: 4rem;
    letter-spacing: 0;
    margin: 0;
}
.header_sm {
    font-family: 'MontserratSemibold600';
    font-size: 2.4rem;
    line-height: 3.2rem;
    letter-spacing: 0;
    margin: 0;
}
.header_xs {
    font-family: 'MontserratSemibold600';
    font-size: 2.2rem;
    line-height: 3.2rem;
    letter-spacing: 0;
    margin: 0;
}
.header_xxs {
    font-family: 'MontserratSemibold600';
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    margin: 0;
}

/* React player in resource blade */
.react-player {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 9/6;
}

/* PDF in resource blade */
.pdf-canvas {
    width: 100% !important;
    height: auto !important;
}
