/* Customize Algolia UI */

/* Search results cards view */
.cards-view .ais-InfiniteHits-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
    place-items: center;
    place-content: center;
    gap: 4.8rem;
}

@media only screen and (min-width: 1441px) {
    .cards-view .ais-InfiniteHits-list {
        width: 80%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1055px) {
    .cards-view .ais-InfiniteHits-list {
        gap: 3.2rem;
    }
}
@media screen and (max-width: 767px) {
    .cards-view .ais-InfiniteHits-list {
        grid-template-columns: 1fr;
        gap: 1.6rem;
    }
}

.cards-view .ais-InfiniteHits-item {
    border-radius: 1.5rem;
    padding: 0;
    margin: 0;
    background: white;
    box-shadow: 0px 2px 15px 0px #4e4e4e59;
    max-height: 32.78vw;
    min-height: 21.67vw;
    width: 100%;
    z-index: 10;
}

.cards-view .ais-InfiniteHits-loadMore {
    display: block;
}

/* Search results LIST view */
.list-view .ais-InfiniteHits-list {
    box-shadow: none;
}

@media only screen and (min-width: 1441px) {
    .list-view .ais-InfiniteHits-list {
        width: 80%;
        margin: 0 auto;
    }
}

.list-view .ais-InfiniteHits-item {
    box-shadow: 0px 2px 15px 0px #4e4e4e59;
    padding: 0;
    border-radius: 1.5rem;
}

/* Algolia SearchBox */
.ais-SearchBox-form:before {
    display: none;
}

.ais-SearchBox {
    flex: 1;
}

.ais-SearchBox-form {
    height: 5.6rem;
    background-color: transparent;
    border: none;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: 'CabinRegular400';
    letter-spacing: 0;
    position: relative;
    display: flex;
    align-items: center;
}

.ais-SearchBox-input {
    width: 100%;
    height: 5.6rem;
    padding: 1.6rem 2.4rem;
    color: rgba(17, 48, 75, 0.5);
    box-shadow: none;
    background-color: #f0f3f7;
    border: 0.1rem solid #ccd6df;
    border-left: none;
    border-radius: 0 5rem 5rem 0;
}

.ais-SearchBox-input:focus {
    border-color: #ccd6df;
    box-shadow: none;
    outline: none;
}

.ais-SearchBox-submit {
    position: absolute;
    right: 0;
    height: 5.6rem;
    width: 10.4rem;
    border-radius: 5rem;
    background-color: #fdd264;
}

.ais-SearchBox-submit:hover {
    background-color: #fcbe22;
}

.ais-SearchBox-reset {
    position: absolute;
    right: 11rem;
}

@media screen and (max-width: 767px) {
    .ais-SearchBox-input {
        height: 4rem;
        border-left: 0.1rem solid #ccd6df;
        border-radius: 5rem;
    }
    .ais-SearchBox-submit {
        position: absolute;
        right: 0;
        height: 4rem;
        width: 4.8rem;
        border-radius: 5rem;
        background-color: #fdd264;
    }

    .ais-SearchBox-reset {
        right: 5rem;
    }
}

/* sortBy dropdown */
.ais-SortBy-select {
    width: 100%;
}

/* CurrentRefinements */
.ais-CurrentRefinements {
    display: flex;
}

.ais-CurrentRefinements-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem 2.4rem;
}

.ais-CurrentRefinements-item {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem 0;
    background-color: transparent;
    border-radius: 0;
    border: none;
}

.ais-CurrentRefinements-label {
    white-space: nowrap;
    display: inline;
    flex-wrap: wrap;
    margin-right: 0.8rem;
}

.ais-CurrentRefinements-category {
    margin: 0;
    color: #4574d0;
    background-color: white;
    border: 0.1rem solid #4574d0;
    border-radius: 5rem;
    white-space: nowrap;
    padding: 0.4rem 1.6rem;
    display: flex;
    margin-right: 0.8rem;
}

.ais-CurrentRefinements-category:last-of-type {
    margin-right: 0;
}

.ais-CurrentRefinements-delete {
    color: #4574d0;
    margin-left: 0.8rem !important;
    height: auto;
}

@media screen and (max-width: 767px) {
    .ais-CurrentRefinements {
        /* width: 100%; */
        padding-right: 2.4rem;
    }

    .ais-CurrentRefinements-list {
        flex-wrap: nowrap;
        grid-gap: 0.8rem;
    }
    .ais-CurrentRefinements-item {
        flex-wrap: nowrap;
        padding: 0;
        gap: 0.8rem 0;
    }
    .ais-CurrentRefinements-label {
        display: none;
    }
}

/* ClearRefinements (clear all button)*/
.ais-ClearRefinements {
    align-self: flex-start;
    margin-left: auto;
}

.ais-ClearRefinements-button {
    all: unset;
    width: auto;
    white-space: nowrap;
    cursor: pointer;
    color: #1e3a71;
    font-family: 'CabinSemibold600';
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    margin: 0;
}

.ais-ClearRefinements-button:hover,
.ais-ClearRefinements-button:active,
.ais-ClearRefinements-button:disabled[disabled] {
    border: none;
    box-shadow: none;
    background-image: none;
    outline: none;
}

.ais-ClearRefinements-home {
    margin-right: 1.5rem;
}

@media screen and (max-width: 767px) {
    .ais-ClearRefinements {
        align-self: unset;
        padding-right: 2.4rem;
    }
}

/* User specific dropdown */
.ais-Menu-count {
    display: none !important;
}

.ais-Menu-label {
    width: 60%;
    margin: 0 auto;
}

.ais-Menu-item--selected {
    color: red !important;
}

/* RefinementList */
.ais-RefinementList-item {
    margin-bottom: 1.6rem;
}
.ais-RefinementList-item:last-of-type {
    margin-bottom: 0;
}

.ais-RefinementList-count {
    display: none;
}

.ais-RefinementList-labelText {
    font-family: 'CabinRegular400';
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0;
}

.ais-RefinementList-checkbox {
    width: 1.8rem;
    height: 1.8rem;
    box-shadow: none;
    border-radius: 0.3rem;
    border: 0.2rem solid #8897a5;
    margin-right: 0.8rem;
}

.ais-GeoSearch-input:checked,
.ais-RefinementList-item--selected .ais-RefinementList-checkbox {
    background-image: url('assets/images/refinementList-checkbox-bg.svg');
    background-size: 1.4rem;
    border-color: #4574d0;
    box-shadow: none;
    color: #4574d0;
}

.ais-GeoSearch-input:checked:focus,
.ais-RefinementList-item--selected .ais-RefinementList-checkbox:focus {
    box-shadow: none;
    outline: none;
}
